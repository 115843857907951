<template>

  <div class="page">

    <!--查询-->
    <div class="search-wrapper" v-show="ui.searchBarStatus">
      <div class="navbar-search float-left">

        <!--栏目名称，单选-->
        <el-select
            v-model="ui.seekName"
            placeholder="请选择栏目"
            class="searchField"
            size="mini">
          <el-option
              v-for="item in caption.colModel"
              v-if="item.search && item.formatter!='seek_date_mark' && item.formatter!='datetime' && item.linkTableName==''"
              :key="item.name"
              :label="item.comment"
              :value="item.name">
          </el-option>
        </el-select>

        <!--查询值-->
        <el-input
            placeholder="请输入要查询的关键字"
            v-model="ui.seekValue"
            class="searchString"
            size="mini">
        </el-input>

        <!--查询按钮-->
        <el-button
            @click="seek()"
            type="primary"
            class="serach-btn"
            icon="el-icon-search"
            size="mini">
          <span>查询</span>
        </el-button>
        <el-button
            @click="reset()"
            type="warning"
            :plain="true"
            class="reset-btn"
            icon="el-icon-refresh-left"
            size="mini">
          <span>重置</span>
        </el-button>

        <!--高级查询-->
        <el-button
            @click="openToolDialog('查询', {ocx:'seek'})"
            type="warning"
            :plain="true"
            class="el-icon-d-arrow-right search-more"
            size="mini"
            title="深度查询">
        </el-button>

      </div>

      <!--小按钮区-->
      <div class="navbar-bars float-right">
        <span
            @mousedown="printBefore()"
            @mouseleave="printAfter()">
        <el-button
            :plain="true"
            size="mini"
            v-print="print"
            title="打印">
          <i class="fa fa-print"></i>
        </el-button>
        </span>
        <el-button
            :plain="true"
            size="mini"
            title="设置"
            @click="setConfig">
          <i class="fa fa-cog"></i>
        </el-button>
        <el-button
            :plain="true"
            size="mini"
            title="导入模板下载"
            @click="downloadFile">
          <i class="fa fa-download"></i>
        </el-button>
      </div>
    </div>

    <!--数据显示区域-->
    <div class="table-wrapper">

      <!--按钮集合-->
      <div class="buttons-wrapper">
        <div class="navbar-buttons float-left">

          <el-button
              @click="openForm()"
              type="primary"
              :plain="true"
              size="mini"
              icon="el-icon-plus">
            <span>新增</span>
          </el-button>



          <el-button
              @click="batchDelete()"
              type="danger"
              :plain="true"
              size="mini"
              icon="el-icon-delete"
              title="批量删除">
            <span>删除</span>
          </el-button>

          <!--全局下拉菜单-->
          <el-dropdown
              @command="masterMenuCmd"
              size="medium"
              trigger="click"
              title="主菜单">
            <el-button
                type="warning"
                :plain="true"
                size="mini"
                class="btn-menu">
              <i class="fa fa-list-ol"></i>
              <i class="fa fa-caret-down i-second"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <template v-for="(menu,masterMenuRowIndex) in masterMenu.menus">
                <el-dropdown-item
                    :divided="masterMenuRowIndex!=0"
                    :style="'color:'+menu.color"
                    :command="{'masterMenuRowIndex':masterMenuRowIndex}">
                  <i v-if="menu.icon" :class="menu.icon"></i>{{ menu.text }}
                </el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button @click="batchAdd()"
                     type="primary"
                     size="mini"><i
              class="fa fa-map-marker"></i> 批量新增
          </el-button>

        </div>

        <!--小按钮区-->
        <div class="navbar-bars float-right">
          <el-button
              :plain="true"
              size="mini"
              @click="openToolDialog('查询', {ocx: 'seek'})" title="深度查询">
            <i class="fa fa-search"></i>
          </el-button>
          <el-button
              :plain="true"
              size="mini"
              @click="initList"
              title="刷新">
            <i class="fa fa-refresh fa-spin"></i>
          </el-button>
          <el-button
              :plain="true"
              size="mini"
              @click="openSlaveDialog('统计', '#/manager/showStatisticsMain?tableName='+caption.tableName)"
              title="统计">
            <i class="fa fa-calculator"></i>
          </el-button>
        </div>

      </div>

      <!--表格，选中翻页是否影响-->
      <el-table
          id="print"
          :row-key="table.key"
          :highlight-current-row="true"
          :highlight-selection-row="true"
          @selection-change="selectionTableChange"
          @sort-change="sortChange"
          @header-dragend="headerDragend"
          @row-dblclick="rowDblclick"
          ref="linkTable"
          :height="table.height"
          :data="tableData"
          :header-cell-style="{fontSize: '15px',fontWeight: '100',backgroundColor:'#FAFAFA',height: '48px'}"
          style="width: 100%"
          stripe
          border>

        <!--暂无数据-->
        <template slot="empty">
          <p>{{ table.emptyText }}</p>
        </template>

        <!--行号-->
        <el-table-column
            type="index"
            label-class-name="row-sn"
            align="center"
            width="50">
        </el-table-column>

        <!--多选框-->
        <el-table-column
            type="selection"
            label-class-name="row-selection"
            align="center"
            width="50">
        </el-table-column>

        <!--最后一列不设置宽度-->
        <template v-for="(cm,index) in caption.colModel">
          <el-table-column
              v-if="!cm.hidden"
              :label-class-name="'draggable-column-'+cm.name"
              :prop="cm.name"
              :label="cm.comment"
              :align="cm.align"
              :sortable="cm.sortable?'custom':false"
              :width="(caption.colModel.length==index+1)?'':cm.width"
              :min-width="cm.width">

            <template slot-scope="scope">

              <!--样式、按钮-->
              <el-button
                  v-if="cm.formatter=='class_name'"
                  :class="scope.row[cm.name]"
                  size="mini">
                样式
              </el-button>
              <el-button
                  v-else-if="cm.formatter=='button_title'"
                  size="mini">
                {{ scope.row[cm.name] }}
              </el-button>

              <!--选框-->
              <template v-else-if="cm.formatter=='checkbox' || cm.formatter=='boolean' || cm.formatter=='check_mark'">
                <el-checkbox
                        v-if="scope.row[cm.name]=='1' || scope.row[cm.name]=='0'"
                        true-label="1"
                        false-label="0"
                        v-model="scope.row[cm.name]"
                        disabled>
                </el-checkbox>
                <el-checkbox
                        v-if="scope.row[cm.name]=='true' || scope.row[cm.name]=='false'"
                        v-model="scope.row[cm.name]"
                        disabled>
                </el-checkbox>
              </template>

              <!--密码、标签、数字-->
              <el-tag
                  v-else-if="cm.formatter=='password'"
                  type="danger" size="small">
                <i class="fa fa-key i-gap"></i>机密信息
              </el-tag>
              <el-tag
                  v-else-if="cm.formatter=='label'"
                  size="small"
                  effect="dark"
                  class="label">
                {{ scope.row[cm.name] }}
              </el-tag>
              <el-tag
                  v-else-if="cm.formatter=='datanumber' || cm.formatter=='number'"
                  size="small"
                  effect="dark"
                  class="label number">
                {{ scope.row[cm.name] }}
              </el-tag>

              <!--FA图标-->
              <span v-else-if="cm.formatter=='fa_icon'" class="navbar-bars">
                <el-button
                    v-if="scope.row[cm.name]!=null && scope.row[cm.name]!=''"
                    :plain="true"
                    size="mini"
                    class="fa_icon">
                  <i :class="scope.row[cm.name]" size="mini"></i>
                </el-button>
              </span>

              <!--日期+时间、金额、电话、邮箱-->
              <span class="datetime"
                    v-else-if="cm.formatter=='datetime' || cm.formatter=='date' || cm.formatter=='time' || cm.formatter=='seek_date_mark'">
                <i v-if="scope.row[cm.name]!=null && scope.row[cm.name]!=''"
                   class="i-gap fa fa-calendar"></i>{{ scope.row[cm.name] }}
              </span>
              <span v-else-if="cm.formatter=='money'">
                <i v-if="scope.row[cm.name]!=null && scope.row[cm.name]!=''"
                   class="i-gap fa fa-cny"></i>{{ scope.row[cm.name] }}
              </span>
              <span v-else-if="cm.formatter=='telephone'">
                <i v-if="scope.row[cm.name]!=null && scope.row[cm.name]!=''"
                   class="i-gap fa fa-phone"></i>{{ scope.row[cm.name] }}
              </span>
              <span v-else-if="cm.formatter=='email'">
                <i v-if="scope.row[cm.name]!=null && scope.row[cm.name]!=''"
                   class="i-gap fa fa-envelope-o"></i>{{ scope.row[cm.name] }}
              </span>

              <!--性别，移入字典方式-->
              <div v-else-if="cm.formatter=='sex'">
                <span v-if="scope.row[cm.name]=='0'">
                  <el-tag
                      type="danger"
                      size="small">
                    <i class="fa fa-user-o i-gap"></i>女
                  </el-tag>
                </span>
                <span v-else>
                  <el-tag
                      type="primary"
                      size="small">
                    <i class="fa fa-user-o i-gap"></i>男
                  </el-tag>
                </span>
              </div>

              <!--源码、代码-->
              <code v-else-if="cm.formatter=='code'" class="code">
                {{ scope.row[cm.name] }}
              </code>

              <!--图片、文件（供下载）-->
              <div v-else-if="cm.formatter=='image_url' || cm.formatter=='header_url' || cm.formatter=='logo'"
                   class="image_url">
                <el-image
                    v-if="scope.row[cm.name]!=null && scope.row[cm.name]!=''"
                    style="width: 100%; height: 80px"
                    :src="apiUrl+'/'+scope.row[cm.name]"
                    fit="contain">
                </el-image>
              </div>
              <div v-else-if="cm.formatter=='file_url'">
                <el-link
                    v-if="scope.row[cm.name]!=null && scope.row[cm.name]!=''"
                    :href="apiUrl+'/'+scope.row[cm.name]"
                    target="_blank">打开连接
                </el-link>
              </div>

              <!--复制、网址-->
              <div v-else-if="cm.formatter=='url' || cm.formatter=='copy'">
                <el-input
                    v-model="scope.row[cm.name]"
                    size="small"
                    v-if="scope.row[cm.name]!=null && scope.row[cm.name]!=''"
                    readonly>
                  <template slot="append"><span><i class="el-icon-document-copy"></i></span></template>
                </el-input>
              </div>

              <!--其余（文字性质）-->
              <span v-else :title="scope.row[cm.name]">
                  {{ scope.row[cm.name] }}
              </span>

            </template>

          </el-table-column>
        </template>

        <!--操作列-->
        <template v-if="table.operBarShow">
          <el-table-column
              label="操作"
              label-class-name="row-oper"
              :width="table.operBarWidth"
              align="center"
              fixed="right">
            <template slot-scope="scope">

              <el-button
                  class="oper-edit"
                  size="mini"
                  plain
                  type="primary"
                  @click="openForm(scope.row[caption.keyIdName])">
                <i class="fa fa-edit"></i><span> 编辑</span>
              </el-button>

              <!--行下拉菜单-->
              <el-dropdown
                  size="medium"
                  trigger="click"
                  @command="slaveMenuCmd"
                  title="从（行）菜单">
                <el-button
                    class="oper-menu"
                    size="mini"
                    plain
                    type="warning">
                  <i class="fa fa-list-ol"></i> <i class="fa fa-caret-down"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <template v-for="(menu,menuRowIndex) in caption.menu">
                    <el-dropdown-item
                        :divided="menuRowIndex!=0"
                        :style="'color:'+menu.color"
                        :command="{'menuRowIndex':menuRowIndex,'dataRowIndex':scope.$index}">
                      <i v-if="menu.icon" :class="menu.icon"></i>
                      {{ menu.text.toLocaleUpperCase() }}
                    </el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </el-dropdown>

            </template>
          </el-table-column>
        </template>

      </el-table>

      <!--分页 layout="total, sizes, prev, pager, next, jumper" -->
      <div class="page-wrapper">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="seekPo.pageNum"
            :page-sizes="[14,28,42]"
            :page-size="seekPo.pageSize"
            :pager-count="5"
            :layout=ui.layout
            :total="seekPo.total">
        </el-pagination>
      </div>

    </div>

    <!--电子表格上传 :headers="headers"-->
    <div style="display: none">
      <el-form ref="form" :model="form">
        <el-upload
            ref="excel_file"
            name="excel_file"
            :on-success="uploadSuccess"
            :action="uploadUrl"
            :auto-upload="true"
            :show-file-list="false"
            :multiple="false"
            :limit="1">
          <el-button
              size="mini"
              type="primary"
              :plain="true"
              id="uploadExcel">
          </el-button>
        </el-upload>
      </el-form>
    </div>

  </div>

</template>

<script>
import {list, getTableCaption, deleteByIds, update} from '@/api/public.js'
import {setTable, getTable} from '@/utils/auth'

export default {

  // 主要点：原始名称
  name: "toilet",

  data() {
    return {

      // 基础路径
      apiUrl: process.env.VUE_APP_BASE_API,
      uploadUrl: '',

      // 界面元素状态
      ui: {
        // 分页
        layout: "total, sizes, prev, pager, next, jumper",

        // 查询
        searchBarStatus: true,
        seekName: '',
        seekValue: '',
      },

      // UI表格高度计算+操作栏
      table: {
        height: 0,
        operBarWidth: 0,
        operBarShow: true,
        emptyText: '',
        keySortOrder: 'desc',
        key: "true",
      },

      // 电子表格上传
      form: {
        'excel_file': '',
      },

      // 栏目信息+审核栏目
      caption: {
        tableName: '',
        tableAlias: '',
        tableTitle: '',
        ids: [],
        // 字典类型ID，快速检索用
        dictType: '',
        // 审核字段信息
        checkMark: {},
        keyIdName: '',
        // 穿越菜单
        menu: [],
      },

      // 查询入参
      seekPo: {
        pageNum: 1,
        pageSize: 14,
        conditionAttach: {
          customOper: [],
          dateInterval: [],
        },
        condition: {},
        total: 0,
        sort: [
          {
            "orderBy": '',
            "sortOrder": "desc"
          }
        ],
      },

      // UI显示数据
      tableData: [],

      // 主菜单
      masterMenu: {
        menuShow: false,
        menus: [{
          text: '查询',
          act: 'seek',
          icon: 'fa fa-search',
        }, {
          text: '统计',
          act: 'statistics',
          icon: 'fa fa-calculator',
        }, {
          text: '导入',
          act: 'import',
          icon: 'fa fa-cloud-upload',
        }, {
          text: '导出',
          act: 'export',
          icon: 'fa fa-cloud-download',
        }, {
          text: '收藏',
          act: 'favorites',
          icon: 'fa fa-star-o',
        }, {
          text: '帮助',
          act: 'helper',
          icon: 'fa fa-handshake-o',
          color: '#ccc',
        }],
      },

      // 局部打印
      print: {
        id: 'print',
        popTitle: '', // 打印页面的页眉
        previewBeforeOpenCallback() {
          console.log('正在加载预览窗口')
        },
        previewOpenCallback() {
          console.log('已经加载完预览窗口')
        },
        beforeOpenCallback(vue) {
          console.log('打开之前')
        },
        openCallback(vue) {
          vue.printAfter();
          console.log('执行了打印')
        },
        closeCallback(vue) {
          vue.printAfter();
          console.log('关闭了打印工具')
        },
        clickMounted(vue) {
          console.log('点击了打印按钮')
        }
      },

    }
  },

  // 页面渲染前
  created() {
    this.initParm();
    this.setResize();
    if (this.caption.tableName) {
      this.initCaption();
    }
  },

  // 页面渲染后
  mounted() {

    // 全局
    window.initList = this.initList;
    window.getSeekPo = this.getSeekPo;
    window.deepSeek = this.deepSeek;
    window.initCaption = this.initCaption;

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }
  },

  beforeUpdate() {
  },

  // 加载靠后，操控元素
  updated() {
    // 设置拖动
    this.initDragEvent();
  },

  // 释放资源
  beforeDestroy() {
  },

  methods: {

    // 初始化参数
    initParm() {

      // 输入参数
      let e = this.$route.query;
      for (let key in e) {
        if (key == "ids") {
          if (e[key]) {
            this.caption[key] = e[key].split(",");
          } else {
            this.caption[key] = [];
          }
        } else if (key == "dictType") {
          this.seekPo['condition']['dictType'] = e[key];
          this.seekPo["conditionAttach"]["customOper"].push({
            "javaName": "dictType",
            "operator": "eq",
          });
        } else {
          this.caption[key] = e[key];
        }
      }

      // 表名
      let tableName = this.$options.name;
      this.caption.tableName = tableName;
      this.caption.tableAlias = tableName.replace(/_/g, "").toLowerCase();
      let tableEntityName = tableName.replace(/_([a-z])/g, function (all, i) {
        return i.toUpperCase();
      })
      tableEntityName = tableEntityName.substring(0, 1).toUpperCase() + tableEntityName.substring(1);
      this.caption.tableClassName = tableEntityName;

    },

    // 栏目信息，组织数据用
    initCaption() {

      getTableCaption(this.caption.tableName).then(res => {

        // 参数，勿重建
        for (let key in res) this.caption[key] = res[key];

        // 数据元
        let colModel = this.caption.colModel;
        let menu = [];
        let dictTypeArr = [];

        for (let key in colModel) {

          // 记录元
          let cm = colModel[key];

          // 穿越菜单
          if (cm['linkTableName']) {
            menu.push({
              'linkTableName': cm['linkTableName'],
              'linkMultiRows': cm['linkMultiRows'],
              'name': cm['name'],
              'text': cm['comment'],
              'icon': 'fa fa-arrow-circle-o-up',
            });
          }

          // 字典类型ID
          if (cm['dictType']) {
            dictTypeArr.push(cm['dictType']);
          }

          // 审核+反审核
          if (cm['formatter'] == 'check_mark') {
            this.masterMenu.menus.push({
              text: '审核',
              act: 'pass',
              icon: 'fa fa-calendar-check-o',
            }, {
              text: '反审核',
              act: 'nopass',
              icon: 'fa fa-calendar-times-o',
            });
            this.caption.checkMark = cm;
          }
        }

        // 加载本地头文件+验证
        this.initStoreCaption();

        // 将必选菜单放后面
        menu.push({
          text: '详情',
          act: 'detail',
          icon: 'fa fa-newspaper-o',
        }, {
          text: '编辑',
          act: 'edit',
          icon: 'fa fa-edit',
        }, {
          text: '删除',
          act: 'delete',
          icon: 'fa fa-trash-o fa-lg',
        });

        // 数据行菜单+字典类型ID
        this.caption.menu = menu;
        this.caption.dictType = dictTypeArr + "";
        this.seekPo.sort[0].orderBy = this.caption.keyIdName;

        // 打印，页眉
        this.print.popTitle = this.caption.tableTitle;

        // 上传路径
        this.uploadUrl = this.apiUrl + "/business/" + this.caption.tableAlias + "/importExcel";

        // 分批更新
        this.last();

        // 加载数据
        this.initList();

      });
    },

    // 载入本地存储
    initStoreCaption() {

      let initBool = false;

      // 本地载入
      let ret = getTable(this.caption.tableName);
      if (!ret['result']) {
        initBool = true;
      } else {
        let storeColModel = ret['colModel'];
        if (!storeColModel) {
          initBool = true;
        } else {

          // 转换载入值
          let storeMap = {};
          for (let index in storeColModel) {
            let scm = storeColModel[index];
            storeMap[scm['name']] = scm;
          }

          // 设置排序值
          let colModel = this.caption["colModel"];
          for (let index in colModel) {
            let cm = colModel[index];
            cm['sortIndex'] = storeMap[cm['name']]['sortIndex'];
            cm['width'] = storeMap[cm['name']]['width'];
            cm['hidden'] = storeMap[cm['name']]['hidden'];
          }

          // 排序
          colModel.sort((a, b) => {
            return a["sortIndex"] - b["sortIndex"];
          });
        }
      }

      // 初始化，全部存储
      if (initBool) {
        let storeColModel = [];
        let colModel = this.caption['colModel'];
        for (let index in colModel) {
          let cm = colModel[index];
          storeColModel.push({
            name: cm['name'],
            hidden: cm['hidden'],
            width: cm['width'],
            sortIndex: index,
          })
        }
        setTable(this.caption.tableName, storeColModel);
      }

    },

    // 数据记录
    initList() {

      // 主键集
      if (this.caption["ids"] != null) this.seekPo["conditionAttach"]["ids"] = this.caption["ids"] + "";
      if (this.caption["inIds"] != null) this.seekPo["conditionAttach"]["inIds"] = this.caption["inIds"];

      // 数据集
      list(this.caption.tableName, this.seekPo).then(res => {

        this.tableData = res.list;
        this.$set(this.seekPo, "total", res.total);

        if (this.tableData.length == 0) this.table.emptyText = '暂无数据';

        // 设置选中项
        this.setSelected();

        // 更新，有缺陷，影响排序
        if (this.table.key == 'true') {
          this.table.key = 'false';
        } else {
          this.table.key = 'true';
        }

        this.last();

      })
    },

    // 设置选中项，在数据更新方法中
    setSelected() {
      this.$nextTick(() => {
        if (this.caption.ids.length > 0) {
          this.tableData.forEach(item => {
            if (this.caption.ids.includes(item[this.caption.keyIdName] + '')) {
              this.$refs.linkTable.toggleRowSelection(item, true);
            }
          });
        } else {
          this.$refs.linkTable.clearSelection();
        }
      });
    },

    // 初始化拖动事件
    initDragEvent() {
      this.$nextTick(() => {

        // 主键排序
        let element = document.querySelector(".el-table th.row-sn:nth-child(1) > div");
        element.setAttribute("title", "主键排序");
        element.removeEventListener("click", this.keySort);
        element.addEventListener("click", this.keySort);

        // 拖拽
        let elements = document.querySelectorAll(".table-wrapper .el-table thead th[class*=draggable-column-]");
        for (let i in elements) {
          let element = elements[i];
          if (typeof element == "object") {
            element.setAttribute("draggable", "true");
            element.removeEventListener('dragend', this.dragEnd, false);
            element.removeEventListener('dragOver', this.dragOver, false);
            element.addEventListener('dragend', this.dragEnd);
            element.addEventListener('dragover', this.dragOver);
          }
        }
      });
    },

    // 检测窗体变化
    setResize() {
      this.$nextTick(() => {
        this.table.height = document.documentElement.clientHeight - 160;
        if (!this.ui.searchBarStatus) {
          this.table.height = this.table.height + 60;
        }
        if (document.documentElement.clientWidth < 768) {
          this.table.operBarWidth = 80;
          this.ui.layout = "prev, pager, next, jumper";
        } else {
          this.table.operBarWidth = 125;
          this.ui.layout = "total, sizes, prev, pager, next, jumper";
        }
      });
      this.last();
    },

    // 设置表格选中状态，估计翻页不行
    selectionTableChange(select) {
      this.caption.ids = [];
      select.forEach(item => {
        let id = item[this.caption.keyIdName] + '';
        if (!this.caption.ids.includes(id)) {
          this.caption.ids.push(id);
        }
      })
      this.$message({
        type: 'warning',
        message: '已选择 ' + this.caption.ids.length + ' 项条目！',
      });
    },

    // 主菜单
    masterMenuCmd(command) {

      let masterMenuRowIndex = command['masterMenuRowIndex'];
      let menu = this.masterMenu.menus[masterMenuRowIndex];

      if (menu["act"] == "seek") {
        this.openToolDialog('查询', {ocx: 'seek'});
      } else if (menu["act"] == "statistics") {
        let linkUrl = "#/manager/showStatisticsMain?tableName=" + this.caption.tableName;
        this.openSlaveDialog("统计", linkUrl)
      } else if (menu["act"] == "import") {
        this.importExcel();
      } else if (menu["act"] == "export") {
        this.exportExcel();
      } else if (menu["act"] == 'pass' || menu["act"] == 'nopass') {
        this.verify(menu["act"]);
      } else if (menu["act"] == "favorites") {
        this.favorites();
      }
    },

    // 从(行)菜单
    slaveMenuCmd(command) {

      let menuRowIndex = command['menuRowIndex'];
      let dataRowIndex = command['dataRowIndex'];

      // 行菜单+行数据
      let menu = this.caption.menu[menuRowIndex];
      let data = this.tableData[dataRowIndex];

      // 依据命令分支
      let act = menu['act'];
      if (act) {
        this.executeAct(menu, data);
      } else {
        this.openDialog(menu, data);
      }
    },

    // 窗体打开
    openSlaveDialog(title, linkUrl) {
      window.parent.openDialog(title, linkUrl);
    },

    // 窗体打开-穿越
    openDialog(menu, data) {
      let linkTableName = menu['linkTableName'];
      let value = data[menu['name']];
      let linkUrl = "#/manager/showTableMain?tableName=" + linkTableName + "&isMastePage=false&ids=" +
          value + "&inIds=true&method=link";
      this.openSlaveDialog(this.caption.tableTitle, linkUrl);
    },

    // 窗体打开-表单
    openForm(id) {
      let title = "新增";
      let linkUrl = "#/manager/show"+this.caption.tableClassName+"Form";
      if (id) {
        title = "编辑";
        linkUrl = linkUrl + "?id=" + id
      }
      this.openSlaveDialog(title, linkUrl);
    },

    // 批量添加
    batchAdd(){

      let title = "新增";
      let linkUrl = "#/manager/showBatchAddForm";
      this.openSlaveDialog(title, linkUrl);
    },

    // 窗体打开-详情
    openDetail(id) {
      let title = "详情";
      let linkUrl = "#/manager/showTableDetail?tableName=" + this.caption.tableName + "&id=" + id
      this.openSlaveDialog(title, linkUrl);
    },

    // 窗体打开-工具窗体-打开查询
    openToolDialog(title, data) {
      //嵌套方式
      let linkUrl = "#/manager/showSeekForm?tableName=" + this.caption.tableName
      if (this.caption.dictType) {
        linkUrl = linkUrl + "&dictType=" + this.caption.dictType
      }
      this.openSlaveDialog("查询", linkUrl);
    },

    // 行菜单-执行命令
    executeAct(menu, data) {
      let act = menu['act'];
      if (act == "edit") {
        this.openForm(data[this.caption.keyIdName]);
      } else if (act == "detail") {
        this.openDetail(data[this.caption.keyIdName]);
      } else if (act == "delete") {
        this.delete(data[this.caption.keyIdName]);
      }
    },

    // 删除-批量-验证
    batchDelete() {
      if (this.caption.ids.length > 0) {
        this.delete(this.caption.ids + '');
      } else {
        let title = '信息提示';
        let message = '请选择删除条目！';
        let type = 'warning';
        this.$alert(message, title, {
          type: type,
          callback: action => {
            this.$message({
              type: type,
              message: message
            });
          }
        });
      }
    },

    // 删除-确认
    delete(ids) {
      this.$confirm('确认删除条目?', '信息提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteByIds(this.caption.tableName, ids).then(res => {
          this.initList();
          let message = res.message;
          let title = '信息提示';
          let type = 'warning';
          this.$alert(message, title, {
            type: type,
            callback: action => {
              this.$message({
                type: type,
                message: message
              });
            }
          });
        });
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        });
      });
    },

    // 分页-设置每页记录数
    handleSizeChange(val) {
      this.seekPo.pageSize = val;
      this.initList();
    },

    // 分页-设置当前第几页
    handleCurrentChange(val) {
      this.seekPo.pageNum = val;
      this.initList();
    },

    // 查询-高级多栏目-时间段
    deepSeek(condition, conditionAttach) {

      // 查询条件
      this.seekPo.pageNum = 1;
      this.seekPo["condition"] = condition;

      // 模型数组
      for (let key in conditionAttach) {
        let keyArr = key.split("_");
        let javaName = keyArr[0];
        let dateInput = keyArr[1];
        if (dateInput == 'startDateInput') {
          let dateInterval = {}
          dateInterval['endDateInput'] = conditionAttach[javaName + "_endDateInput"];
          dateInterval['startDateInput'] = conditionAttach[key];
          dateInterval['javaName'] = javaName;
          this.seekPo["conditionAttach"]["dateInterval"].push(dateInterval);
        }
      }

      // 更新+关闭
      this.initList();
      window.parent.closeDialog();
    },

    // 查询-普通单栏目
    seek() {

      let type = 'warning';
      if (this.ui.seekName && this.ui.seekValue) {
        this.seekPo.pageNum = 1;

        this.seekPo.condition = {};
        this.seekPo.condition[this.ui.seekName] = this.ui.seekValue;

        this.initList();

      } else {
        this.$message({
          type: type,
          message: '请选择查询栏目并输入关键字！'
        });
      }
    },

    // 查询-重置
    reset() {
      this.ui.seekName = '';
      this.ui.seekValue = '';
      this.seekPo.condition = {};
      this.seekPo.conditionAttach = {};
      this.initList();
    },

    // 查询-获得条件
    getSeekPo() {
      return this.seekPo;
    },

    // 导入
    importExcel() {

      // 先清空，有限定1，名字不重复等
      this.$refs['excel_file'].clearFiles();
      document.getElementById("uploadExcel").click();
    },

    // 导入-成功回调
    uploadSuccess(res, file, fileList) {

      let type = 'warning';
      this.$message({
        type: type,
        message: res.message
      });

      this.initList();
    },

    // 导出-数据，时间段无法带入，需调整
    exportExcel() {

      let form = document.createElement('form')
      form.action = this.apiUrl + "/business/" + this.caption.tableAlias + "/downExcel";
      form.method = 'post'
      form.style.display = 'none'

      let param = this.seekPo.condition;
      for (let item in param) {
        let input = document.createElement('input')
        input.type = 'hidden'
        input.name = item
        input.value = param[item]
        form.appendChild(input)
      }
      document.body.appendChild(form)
      form.submit()
      document.body.removeChild(form)

      let type = 'warning';
      this.$message({
        type: type,
        message: "执行完毕！"
      });

    },

    // 导出-模板
    downloadFile() {
      const fileUrl = this.apiUrl + '/business/' + this.caption.tableAlias + '/downExcelTemplate'; // 文件的URL地址
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', "下载");
      link.click();

      let type = 'warning';
      this.$message({
        type: type,
        message: "执行完毕！"
      });
    },

    // 审核
    verify(act) {

      let val = act == 'pass' ? '1' : '0';
      let msg = act == 'pass' ? '审核' : '反审核';
      let type = 'warning';

      let pojo = {};
      pojo[this.caption.checkMark["name"]] = val;
      if (this.caption.ids.length != 0) {
        for (let key in this.caption.ids) {
          let id = this.caption.ids[key];
          update(this.caption.tableName, {
            'id': id,
            'pojo': pojo,
          }).then(res => {
            this.$message({
              type: type,
              message: res.message
            });
            this.initList();
          });
        }
      } else {
        this.$message({
          type: type,
          message: "请选择" + msg + "项"
        });
      }
    },

    // 排序-栏目
    sortChange(data) {

      let type = 'warning';
      let sortOrder = "";
      let label = data["column"]["label"];
      this.seekPo.sort[0].orderBy = data["prop"];

      if (data["order"] == "ascending") {
        this.seekPo.sort[0].sortOrder = "asc"
        sortOrder = "正序";
      } else if (data["order"] == "descending") {
        this.seekPo.sort[0].sortOrder = "desc"
        sortOrder = "倒序";
      }

      if (sortOrder != "") {
        this.initList();
        this.$message({
          type: type,
          message: '按' + label + sortOrder + '排列！'
        });
      }
    },

    // 排序-主键
    keySort() {

      let type = 'warning';
      let sortOrder = this.table.keySortOrder;

      if (sortOrder == "desc") {
        sortOrder = "asc";
      } else {
        sortOrder = "desc";
      }

      this.table.keySortOrder = sortOrder;
      this.seekPo.sort[0].orderBy = this.caption.keyIdName;
      this.seekPo.sort[0].sortOrder = sortOrder;

      this.initList();
      this.$message({
        type: type,
        message: '按主键' + (sortOrder == 'desc' ? '倒序' : '正序') + '排列！'
      });
    },

    // 打印-前
    printBefore() {
      this.table.operBarShow = false;
    },

    // 打印-后
    printAfter() {
      this.table.operBarShow = true;
    },

    // 栏目-宽度-拖动
    headerDragend(newWidth, oldWidth, column, event) {

      let colModel = this.caption["colModel"];
      let index = colModel.findIndex((item, index) => {
        return item['name'] == column['property'];
      });
      let cm = colModel[index];
      cm["width"] = column['width'];

      let ret = getTable(this.caption.tableName);
      let storeColModel = ret["colModel"];
      if (storeColModel) {
        for (let i in storeColModel) {
          let scm = storeColModel[i];
          if (cm['name'] == scm['name']) {
            scm['width'] = cm['width'];
            break;
          }
        }
      }
      setTable(this.caption.tableName, storeColModel);

      this.$message({
        type: 'warning',
        message: column['label'] + '宽度调整为' + column['width'] + '像素！'
      });
    },

    // 栏目-位置-拖动结束位置
    dragOver(event) {

      let className = event.target.getAttribute("class")
      if (className) {
        let mark = "draggable-column-";
        let nameArr = className.split(" ");
        for (let index in nameArr) {
          let endName = nameArr[index];
          if (endName.includes(mark)) {
            endName = endName.split(mark)[1]
            this.caption["endName"] = endName;
            break;
          }
        }
      }
    },

    // 栏目-位置-拖动-结束
    dragEnd(event) {

      // 起始、终止名称
      let startName = "";
      let endName = this.caption["endName"];
      let target = event.target;
      let className = target.getAttribute("class")
      let title = target.innerText

      if (className) {
        let mark = "draggable-column-";
        let nameArr = className.split(" ");
        for (let index in nameArr) {
          startName = nameArr[index];
          if (startName.includes(mark)) {
            startName = startName.split(mark)[1]
            break;
          }
        }
      }

      if (startName && endName) {

        // 载入本地存储
        let ret = getTable(this.caption.tableName);
        let storeColModel = ret["colModel"];
        if (storeColModel) {

          // 获得交换对象头
          let startColModel = {};
          let endColModel = {};

          storeColModel.forEach(item => {
            if (item["name"] == startName) {
              startColModel = item;
            }
            if (item["name"] == endName) {
              endColModel = item;
            }
          });

          // 交换排序值
          let startSortIndex = startColModel["sortIndex"];
          let endSortIndex = endColModel["sortIndex"];
          startColModel["sortIndex"] = endSortIndex;
          endColModel["sortIndex"] = startSortIndex;

          // 存储
          setTable(this.caption.tableName, storeColModel);

          // 重载
          this.initCaption();

          // 栏目顺序变更
          this.$message({
            type: 'warning',
            message: title + '栏目排序变更！'
          });

        }
      }
    },

    // 单击行
    rowDblclick(row, column, event) {
      this.openForm(row[this.caption.keyIdName]);
    },

    // 栏目设置
    setConfig() {
      let linkUrl = "#/manager/setTableColumn?tableName=" + this.caption.tableName;
      this.openSlaveDialog("栏目设置", linkUrl)
    },

    // 收藏
    favorites() {
      let message = this.caption.tableTitle + "，添加到收藏！";
      let localStorage = window.localStorage;
      if (localStorage) {
        let local = localStorage.getItem("favorites");
        if (local) {
          let localArr = JSON.parse(local);
          if (!localArr.includes(this.caption.tableName)) {
            localArr.push(this.caption.tableName);
            localStorage.setItem("favorites", JSON.stringify(localArr));
          }
        } else {
          localStorage.setItem("favorites", JSON.stringify([this.caption.tableName]));
        }
      } else {
        message = '不支持本地存储！';
      }
      this.$message({
        type: 'warning',
        message: message
      });
    },

    // 后退-后退
    close() {
      window.parent.closeDialog();
    },

    // 更新
    last() {
      this.$forceUpdate();
    },

  },
}

</script>

<style lang="scss">

/*--基础+加载动画--*/
body {
  margin: 0px;
  padding: 0px;
  overflow-y: hidden;

  animation: page-fade-in 1s forwards;
  /*animation: async 0.5s ease-in;*/
  background: url(../../static/images/bodyback.jpg);
}

@keyframes page-fade-in {
  0% {
    opacity: 0
  }
  25% {
    opacity: 0.25
  }
  100% {
    opacity: 1
  }
}

/*--异步动画--*/
@keyframes async {
  0% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

/*--滚动条样式--*/
#app .el-table__body-wrapper::-webkit-scrollbar {
  border-radius: 10px;
  background-color: #F5F5F5;
}

#app .el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#app .el-table__body-wrapper::-webkit-scrollbar-thumb {
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
  background-color: rgba(153, 153, 153, 0.05);
  background-image: url(../../static/images/icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

.float-left {
  float: left;
}

.center {
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
}

.float-right {
  float: right;
}

/*--查询--*/
.search-wrapper, .notice-wrapper {

  margin: 0px 10px;
  margin-top: 10px;
  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);

  .search-more {
    background-image: linear-gradient(to top, #C71A1A 5%, #FFF2EA 10%);
  }

  .navbar-search {

    display: block;

    .searchField {
      width: 115px;
      margin-right: 5px;
    }

    .searchString {
      width: 160px;
      margin-right: 5px;
    }

    .el-button {
      width: 70px;
      padding: 7px 10px;
      margin-left: 0px;
      margin-right: 5px;
    }

    .search-more {
      width: auto;
      padding: 7px;
      margin-left: 0px;
      margin-right: 0px;
    }

  }

}

/*--按钮集--*/
.buttons-wrapper {

  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  border-bottom: 1px solid #EBEEF5;

  .btn-menu {
    background-image: linear-gradient(to top, #C71A1A 5%, #FFF2EA 10%);
  }

  .navbar-buttons {

    .el-button {
      padding: 7px 10px;
      margin-right: 5px;
      margin-left: 0px;
    }

    .btn-menu {
      width: auto;
      padding: 7px;
      margin-left: 0px;
      margin-right: 0px;

      .i-second {
        margin-left: 5px;
      }
    }

    .el-dropdown {
      margin-right: 5px;
    }
  }
}

/*--小按钮--*/
.navbar-bars {

  display: block;

  .el-button {
    padding: 7px 8px;
    margin-left: 5px;
    margin-right: 0px;
    color: #909399;
    border-radius: 10px;
  }
}

/*--表格--*/
.table-wrapper {

  margin: 0px 10px;
  margin-top: 10px;

  background: #FFF;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;

  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

  .el-table--border, .el-table--group {
    border: 0px;
  }

  .el-table thead .cell {
    white-space: nowrap;
    word-break: break-all;
  }

  .el-table thead .el-table__cell {
    padding: 0px !important;
    max-height: 45px !important;
    height: 45px !important;
  }

  .el-table tbody .el-table__cell {

    opacity: 0.95 !important;
    color: rgba(23, 28, 25, 0.75) !important;
    padding: 8px 0px !important;

    min-height: 45px !important;
    height: 45px !important;
  }

  .el-table .cell .fa-caret-down {
    margin-left: 2px;
  }

  .oper-edit, .oper-select {
    background-image: linear-gradient(to top, #4094ff 5%, #F4F4F4 10%);
    padding: 7px;
    margin-right: 5px;
    margin-left: 0px;
  }

  .oper-menu {
    background-image: linear-gradient(to top, #C71A1A 5%, #FFF2EA 10%);
    padding: 7px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .el-table {
    background: url(../../static/images/bg.jpg);
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  .el-table__empty-text {
  }

  /*--网址--*/
  .el-input-group__append {
    padding: 0 7px;
  }

  .el-input-group {
    max-width: 320px;
  }

  .el-input__inner, .el-input__inner:focus {
    border-color: #DCDFE6;
    color: #909399;
    padding: 0 5px;
    font-size: 12px;
  }

  /*--主键排序--*/
  .el-table th.row-sn:nth-child(1) > div:before {
    content: "\f0ec";
    font-weight: 200;
    font: normal normal normal 12px/1 FontAwesome;
    padding: 3px 5px;
    border-radius: 5px;
  }

}

/*--分页--*/
.page-wrapper {
  margin: 0px;
  padding: 5px;
  background: #FFF;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  text-align: center;
}

/*--alert弹框--*/
.el-message-box {
  max-width: 80%;
}

/*--图标+右间隔+图片--*/
.i-gap {
  margin-right: 5px;
}

.slot-image {
  width: 100%;
}

.image_url {
  margin: -2px -10px;
}

/*--源码--*/
code {
  background-color: #F9F2F4;
  border-radius: 4px;
  color: #ca4440;
  font-size: 90%;
  padding: 2px 4px;
  white-space: pre-wrap;
}

/*--日期--*/
.datetime {
  color: #909399;
  font-family: "Helvetica Neue", "open sans", Helvetica, Arial, sans-serif;

  i {
    color: #c7c7c7;
  }
}

/*--标签--*/
.label {
  background-color: rgb(160, 207, 255);
  border-radius: 10px;
  color: rgb(255, 255, 255);
  border: 0px;
  height: 22px;
  line-height: 22px;
  padding: 0px 10px;
}

/*--数字--*/
.number {
  padding: 0px 15px;
  height: 20px;
  line-height: 20px;
}

/*--数字选择器--*/
.el-input-number--mini {
  width: 100%;

  .el-input__inner {
    padding-left: 0px;
    padding-right: 0px;
    background-color: #FFF !important;
  }

  .el-input-number__increase, .el-input-number__decrease {
    width: 12px;
    font-size: 12px;
  }
}

/*--响应式--*/
@media (max-width: 768px) {

  .search-wrapper {
    .el-button {
      width: 35px !important;

      span {
        display: none;
      }
    }
  }

  .navbar-bars, .oper-edit {
    display: none !important;
  }

  .el-dialog {
    margin-top: 0px !important;
    height: 100%;
    margin-left: 0px !important;
    width: 100%;
  }

  .table-wrapper {

    ::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
      border-radius: 0px !important;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0px !important;
    }

    ::-webkit-scrollbar-track {
      border-radius: 0px !important;
    }
  }

}

</style>